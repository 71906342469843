import { Routes, Route } from 'react-router-dom';

import DoctorPage from '../pages/DoctorPage';
import AreaPage from '../pages/AreaPage';
import SignUp from '../pages/SignUp';

const Navigation = () => (
  <Routes>
    <Route path='/doctors' element={<DoctorPage />} />
    <Route path='/area' element={<AreaPage />} />
    <Route path='/signup' element={<SignUp />} />
  </Routes>
);

export default Navigation;
