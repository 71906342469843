// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Table } from 'flowbite-react';
import './AreaPage.css';

// import { addMember } from '../slices/memberSlice';

import TutorialService from '../services/TutorialService';

const AreaPage = () => {
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [distances, setDistances] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const resultRegions = await TutorialService.getRegionList('');
      setRegions(resultRegions?.data?.result);
      const resultAreas = await TutorialService.getAreaByRegionId(
        resultRegions?.data?.result[0]?.id
      );
      setAreas(resultAreas?.data?.result);
      const resultDistances = await TutorialService.getAllDistance();
      setDistances(resultDistances?.data?.result);
    };
    getData();
  }, []);

  const getAreasByRegionId = async (regionId) => {
    try {
      const resultData = await TutorialService.getAreaByRegionId(regionId);
      setAreas(resultData?.data?.result);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='pt-10'>
      <div className='w-[90%] m-auto mb-10 bg-white'>
        <label
          htmlFor='countries'
          className='block mb-2 text-md font-medium text-gray-900 dark:text-white'
        >
          Select a region
        </label>
        <select
          id='countries'
          className='border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-white'
          onChange={(evt) => getAreasByRegionId(evt.target.value)}
        >
          {regions.map((region) => (
            <option value={region?.id} key={region?.id}>
              {region?.name}
            </option>
          ))}
        </select>
      </div>
      <div className='w-[100%] sm:w-[90%] m-auto mb-6'>
        <Table>
          <Table.Head>
            <Table.HeadCell>No</Table.HeadCell>
            <Table.HeadCell>Area Name</Table.HeadCell>
            <Table.HeadCell>Distance</Table.HeadCell>
            <Table.HeadCell>Area ID</Table.HeadCell>
          </Table.Head>
          <Table.Body className='divide-y'>
            {areas.map((area, index) => (
              <Table.Row className='bg-white dark:border-gray-700 dark:bg-gray-800' key={area?.id}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{area?.area_name}</Table.Cell>
                <Table.Cell>{area?.distance}</Table.Cell>
                <Table.Cell>{area?.area_id}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className='w-[90%] m-auto'>
        {distances.map((distance) => (
          <div className='flex' key={distance?.id}>
            <p className='text-lg text-yellow-700'>
              {distance?.distance} : ${distance?.price}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AreaPage;
