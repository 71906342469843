import PropTypes from 'prop-types';
import config from '../config';

const DoctorCard = ({ data }) => {
  const backendImageUrl = `${config.SERVER_URL}/images/people`;

  return (
    <div className='w-[480px] h-[280px] border border-solid border-gray rounded-xl p-6 shadow-lg m-auto card-animation'>
      <div className='flex w-full'>
        <div>
          <img
            src={`${backendImageUrl}/${data.photourl}.png`}
            alt='photoImage'
            className='w-[100px] rounded-md relative top-1'
          />
        </div>
        <div className='ml-5'>
          <div className='flex justify-between w-[300px]'>
            <p className='font-bold text-teal-600 text-xl'>Dr. {data?.name}</p>
            <p className='text-lg text-red-700 relative -top-3 left-3'>${data.price}</p>
          </div>
          <p className='text-sm text-teal-600 mt-1'>{data?.role}</p>
          <div className='flex mt-4'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='16'
              width='18'
              viewBox='0 0 576 512'
              className='mr-2 relative top-[5px]'
            >
              <path d='M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1V192c0 53 43 96 96 96s96-43 96-96V71.1l-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7C334.4 19.1 352 43.5 352 71.1V192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112V265.3c-28.3-12.3-48-40.5-48-73.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V336c0 97.2-78.8 176-176 176c-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192V71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z' />
            </svg>
            <p className='text-md text-gray-800'>{data?.visit_method}</p>
            {data.visit_method === 'both' && (
              <p className='text-md ml-2 text-gray-800'>(Invite Fee: +${data?.invite_price})</p>
            )}
          </div>

          {/* <p className='text-sm'>{data?.region_name}</p> */}
          <div className='flex  mt-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='16'
              width='20'
              viewBox='0 0 640 512'
              className='mr-2 relative top-[4px]'
            >
              <path d='M192 48c0-26.5 21.5-48 48-48H400c26.5 0 48 21.5 48 48V512H368V432c0-26.5-21.5-48-48-48s-48 21.5-48 48v80H192V48zM48 96H160V512H48c-26.5 0-48-21.5-48-48V320H80c8.8 0 16-7.2 16-16s-7.2-16-16-16H0V224H80c8.8 0 16-7.2 16-16s-7.2-16-16-16H0V144c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v48H560c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v64H560c-8.8 0-16 7.2-16 16s7.2 16 16 16h80V464c0 26.5-21.5 48-48 48H480V96H592zM312 64c-8.8 0-16 7.2-16 16v24H272c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V152h24c8.8 0 16-7.2 16-16V120c0-8.8-7.2-16-16-16H344V80c0-8.8-7.2-16-16-16H312z' />
            </svg>
            <p className='text-md text-gray-800'>{data?.hospital}</p>
          </div>

          <div className='flex  mt-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='16'
              width='12'
              viewBox='0 0 384 512'
              className='mr-2 relative top-[4px] left-1'
            >
              <path d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z' />
            </svg>
            <p className='text-md text-gray-800 ml-1'>{data?.address}</p>
          </div>
        </div>
      </div>
      <div className='flex space-x-2 mt-8 scroll pb-3 custom-scroll'>
        {data.expert_in.split(',').map((expert) => (
          <p
            className='text-white rounded-2xl p-1 whitespace-nowrap'
            style={{ backgroundColor: 'rgba(57,179,47,0.85)', fontSize: '14px' }}
          >
            &nbsp;{expert}&nbsp;
          </p>
        ))}
      </div>
    </div>
  );
};

DoctorCard.propTypes = {
  data: PropTypes.node.isRequired,
};

export default DoctorCard;
