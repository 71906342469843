// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

// import { addMember } from '../slices/memberSlice';

// import Layout from '../components/Layout';
import DoctorCard from '../components/DoctorCard';
import TutorialService from '../services/TutorialService';

const DoctorPage = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [doctors, setDoctors] = useState([]);
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const resultRegions = await TutorialService.getRegionList('');
      setRegions(resultRegions?.data?.result);
      const resultData = await TutorialService.getDoctorList(
        '',
        resultRegions?.data?.result[0]?.id
      );
      setDoctors(resultData?.data?.result);
    };
    getData();
  }, []);

  const getDoctorsByRegionId = async (regionId) => {
    try {
      const resultData = await TutorialService.getDoctorList('', regionId);
      setDoctors(resultData?.data?.result);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='pt-10'>
      <div className='w-[90%] m-auto mb-10 bg-white'>
        <label
          htmlFor='countries'
          className='block mb-2 text-md font-medium text-gray-900 dark:text-white'
        >
          Select a region
        </label>
        <select
          id='countries'
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          onChange={(evt) => getDoctorsByRegionId(evt.target.value)}
        >
          {regions.map((region) => (
            <option value={region?.id} key={region?.id}>
              {region?.name}
            </option>
          ))}
        </select>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6 custom-font'>
        {doctors.map((doctor) => (
          <DoctorCard data={doctor} key={doctor?.id} />
        ))}
      </div>
    </div>
  );
};

export default DoctorPage;
