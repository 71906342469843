import React, { useEffect, useState } from 'react';
import { TERipple } from 'tw-elements-react';
import { Label, TextInput } from 'flowbite-react';
import { ToastContainer, toast } from 'react-toast';
import TutorialService from '../services/TutorialService';

export default function SignUp() {
  const [inputData, setInputData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    phoneNumber: 0,
  });
  useEffect(() => {}, []);

  const createSponsor = async () => {
    if (
      inputData.firstName === '' ||
      inputData.lastName === '' ||
      inputData.address === '' ||
      inputData.phoneNumber === 0
    ) {
      toast.error('All fields must be entered.');
    } else {
      const sendData = {
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        address: inputData.address,
        phoneNumber: `whatsapp:+${inputData.phoneNumber}`,
      };
      const resultData = await TutorialService.createSponsor(sendData);
      if (resultData.data?.status === 200) {
        setInputData({
          firstName: '',
          lastName: '',
          address: '',
          phoneNumber: 0,
        });
        toast.success('You sent request successfully');
      } else {
        toast.warn('You already sent request');
      }
    }
  };

  return (
    <section className='h-full bg-neutral-200 dark:bg-neutral-700'>
      <div className='container h-full p-10 m-auto'>
        <div className='g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200'>
          <div className='w-full'>
            <div className='block rounded-lg bg-white shadow-lg dark:bg-neutral-800'>
              <div className=''>
                {/* <!-- Left column container--> */}
                <div className='px-4'>
                  <div className='md:mx-6 md:p-12'>
                    {/* <!--Logo--> */}
                    <div className='text-center'>
                      <img className='mx-auto w-48' src='images/logo.jpg' alt='logo' />
                      <h4 className='mb-12 mt-1 pb-1 text-xl font-semibold'>
                        One Stop <span className='text-green-700'>HealthSphere</span> Solutions
                      </h4>
                    </div>
                    <form>
                      <div className='grid grid-cols-2 gap-10'>
                        {/* <!--First Name input--> */}
                        <div>
                          <div className='mb-2 block'>
                            <Label htmlFor='firstnameid' value='First Name' />
                          </div>
                          <TextInput
                            id='firstnameid'
                            type='text'
                            placeholder='Samuel'
                            onChange={(evt) =>
                              setInputData({ ...inputData, firstName: evt.target.value })
                            }
                            value={inputData.firstName}
                            required
                          />
                        </div>
                        {/* <!--Last Name input--> */}
                        <div>
                          <div className='mb-2 block'>
                            <Label htmlFor='lastnameid' value='Last Name' />
                          </div>
                          <TextInput
                            id='lastnameid'
                            type='text'
                            placeholder='Jesus'
                            onChange={(evt) =>
                              setInputData({ ...inputData, lastName: evt.target.value })
                            }
                            value={inputData.lastName}
                            required
                          />
                        </div>
                      </div>
                      {/* <!--Address input--> */}
                      <div>
                        <div className='mb-2 block'>
                          <Label htmlFor='addressid' value='Address' />
                        </div>
                        <TextInput
                          id='addressid'
                          type='text'
                          placeholder='123 Wellington Street Harare'
                          onChange={(evt) =>
                            setInputData({ ...inputData, address: evt.target.value })
                          }
                          value={inputData.address}
                          required
                        />
                      </div>
                      {/* <!--Phone Number input--> */}
                      <div>
                        <div className='mb-2 block'>
                          <Label htmlFor='phonenumberid' value='Phone Number' />
                        </div>
                        <TextInput
                          id='phonenumberid'
                          type='text'
                          placeholder='9999999999'
                          onChange={(evt) => {
                            let inputValue = evt.target.value;
                            console.log(inputValue);
                            let phoneNumber = 0;
                            if (inputValue === '') inputValue = 0;
                            phoneNumber = parseInt(inputValue, 10);
                            setInputData({ ...inputData, phoneNumber });
                          }}
                          value={inputData?.phoneNumber}
                          required
                        />
                      </div>
                      <p className='text-sm ml-1 text-red-700'>
                        You have to enter the phone number you use for WhatsApp.
                      </p>
                      {/* <!--Submit button--> */}
                      <div className='mb-12 pb-1 pt-1 text-center mt-16'>
                        <TERipple rippleColor='light' className='w-full'>
                          <button
                            className='mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] bg-green-700'
                            type='button'
                            // style={{
                            //   background:
                            //     'linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)',
                            // }}
                            onClick={createSponsor}
                          >
                            Register
                          </button>
                        </TERipple>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position='top-right' delay={3000} />
    </section>
  );
}
