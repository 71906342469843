import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Config from './config';

const axiosInstance = axios.create({
  baseURL: Config.SERVER_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    let decodeToken = '';
    const userToken = localStorage.getItem('user');
    if (userToken) decodeToken = jwtDecode(userToken);
    if (decodeToken) {
      // Add the token to the Authorization header
      config.headers.Authorization = `Bearer ${userToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
