// import jwtDecode from 'jwt-decode';
import http from '../http-common';

const getDoctorList = (searchDoctor, regionId) => {
  const send = {
    searchDoctor,
    regionId,
  };

  return http.post('/manage/getdoctorlist', send);
};

const getRegionList = (search) => http.post('/manage/getregionlist', { search });

const getAreaByRegionId = (regionId) => http.post('/manage/getareabyregionid', { regionId });

const getAllDistance = () => http.get('/manage/getalldistance');

const createSponsor = (sendData) => http.post('/manage/createsponsor', sendData);

const TutorialService = {
  getDoctorList,
  getRegionList,
  getAreaByRegionId,
  getAllDistance,
  createSponsor,
};

export default TutorialService;
